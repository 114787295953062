import React, { useEffect, useState } from "react";

const DownloadLink = ({ fileName }) => {
  const [fileUrl, setFileUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const loadFile = async () => {
      try {
        const file = await import(`../storage/${fileName}`);
        setFileUrl(file.default);
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    loadFile();
  }, [fileName]);

  if (loading) {
    //return <p>Načítání...</p>;
  }

  if (error) {
    //return <p>Soubor {fileName} nebyl nalezen.</p>;
  }

  return (
    <a className="button button--green" href={fileUrl} title="Stáhnout PDF" target="_blank" rel="noreferrer">Stáhnout PDF</a>
  );
};

export default DownloadLink;
