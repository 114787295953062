import { useEffect, useState } from "react";
import { initSmoothScroll } from "./utils";
import Use from "./Use";
import $ from "jquery";
import Breadcrumbs from "./Breadcrumbs";
import GoogleMapReact from "google-map-react";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import classNames from "classnames";
import axios from "axios";
import Cookies from "js-cookie";
import Logo from "../themes/frontend/img/logo/logo.svg";
import Image from "./Image";
import { obfuscateEmail } from "./utils";

const openBox = () => {
  $(".infobox__content").removeClass("infobox__content--close").addClass("infobox__content--open");
}

const closeBox = () => {
  $(".infobox__content").removeClass("infobox__content--open").addClass("infobox__content--close");
}

const AnyReactComponent = () =>
  <div className="infobox">
    <div className="infobox__content infobox__content--close">
      <div className="infobox__name">Pastor & syn, s.r.o.</div>
      <div className="infobox__text">Modrá 44, 407 01 Jílové</div>
      <div className="infobox__tools">
        <a className="infobox__directions" href="https://www.google.com/maps/place/Pastor+%26+syn,+s.r.o/@50.7606341,14.0816588,17z/data=!3m1!4b1!4m6!3m5!1s0x47099945f4d69645:0x301a09443e1a639d!8m2!3d50.7606341!4d14.0842337!16s%2Fg%2F11c2j7n4vd?entry=ttu" target="_blank" rel="noreferrer">Trasa</a>
      </div>
      <button className="infobox__close" aria-label="Zavřít" onClick={closeBox}>&times;</button>
    </div>
    <svg className="infobox__marker" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 275.12 351.02" onClick={openBox}>
      <path d="m275.12,137.56C275.12,61.59,213.53,0,137.56,0S0,61.59,0,137.56c0,9.23.92,18.25,2.65,26.97.09.71.18,1.41.27,2.1,0,0,9.27,72.01,125.6,180.88,1.83,1.72,3.95,2.58,3.95,2.58,1.57.61,3.32.93,5.09.93s3.51-.32,5.09-.93c0,0,2.12-.86,3.95-2.58,80.33-75.17,109.51-133.26,119.84-161.78,5.61-14.99,8.68-31.23,8.68-48.18Z" fill="#223758" />
    </svg>
  </div>

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ""}${expires}; path=/`;
};

const Kontakt = () => {
  const breadcrumbs = [
    { label: "Kontakt", link: "/kontakt" }
  ];

  const [googleAccepted, setGoogleAccepted] = useState(getCookie("google_accepted") === "true");

  const [formInteracted, setFormInteracted] = useState(false);

  const { register, handleSubmit, formState: { errors, isSubmitted }, trigger, getValues, setValue } = useForm({
    criteriaMode: "all",
    mode: "onChange"
  });

  const navigate = useNavigate();

  useEffect(() => {
    initSmoothScroll();

    $(function() {
      if(window.location.hash) {
        setTimeout(function() {
          $("html,body").stop().animate({
            scrollTop: $(window.location.hash).offset().top
          }, 1200);
        }, 50);
      }
    });

    const savedFormData = sessionStorage.getItem("contactForm");
    if (savedFormData) {
      const formData = JSON.parse(savedFormData);
      for (const [key, value] of Object.entries(formData)) {
        setValue(key, value);
      }
    }
  }, [setValue]);

  const handleAccept = () => {
    setCookie("google_accepted", "true", 7);
    setGoogleAccepted(true);
  };

  const onSubmit = (data) => {
    try {
      const form_data = new FormData();
      form_data.append("sender_domain", "pastorasyn.cz"); // doména, ze které se posílá email
      form_data.append("api_key", process.env.REACT_APP_API); // klíč k API, jako heslo
      form_data.append("from", data.email); // od koho mail bude
      form_data.append("fromname", data.name + " " + data.surname); // jméno od koho je
      form_data.append("to", "pastorl@pastorasyn.cz"); // email komu
      form_data.append("toname", "Pastor & syn, s r. o."); // jméno adresáta
      form_data.append("subject", "Nová zpráva z formuláře"); // předmět
      form_data.append("body", "Dobrý den,<br>z Vašeho webu byl právě odeslán formulář:<br><br>Jméno: " + data.name + "<br>Příjmení: " + data.surname + "<br>Telefon: " + data.phone + "<br>Email: " + data.email + "<br>Zpráva: " + data.message); // tělo emailu
      form_data.append("htmlformat", 1);
      axios.post("https://rmail.core1.agency/send_mail", form_data) // pošle request na api
    } catch(error) {};
    Cookies.set("formSubmitted", true);
    sessionStorage.removeItem("contactForm");
    navigate("/formular-odeslan");
  }

  const getGroupClassNames = (fieldName) => {
    const fieldValue = getValues(fieldName);
    const isTouchedAndValid = fieldValue && !errors[fieldName];

    return classNames("form__group", {
      "form__group--error": errors[fieldName],
      "form__group--success": isTouchedAndValid
    });
  };

  const renderErrorMessage = ({ message }, fieldName) => {
    return <small className="form__help">{message}</small>;
  };

  const handleChange = (fieldName) => {
    setFormInteracted(true);
    trigger(fieldName);
    const formData = getValues();
    sessionStorage.setItem("contactForm", JSON.stringify(formData));
  };

  return (
    <>
      <Use clas="" title="Kontakty Pastor & syn, s r. o." description="Kontakty Pastor & syn, s r. o. Jílové" />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <div className="block block--video block--half padding pt-0">
        <div className="relative container container--full">
          <div className="clip clip--green clip--absolute"></div>
          <div className="container container--alt">
            <div className="row">
              <div className="col-md-5 align-self-center">
                <div className="relative clip clip--green clip--sm color--white padding">
                  <h1 className="block__title block__title--margin mb">Váš spolehlivý partner ve zpracování plastů.</h1>
                  <p className="block__small mb">Kontaktujte nás, pokud hledáte spolehlivého partnera a záruku kvality ve výrobě plastových výrobků.</p>
                  <a className="button button--blue button--top scroll" href="#poptavkovy-formular" title="Poptávkový formulář">Poptat nás</a>
                </div>
              </div>
            </div>
          </div>
          <div className="map">
            {googleAccepted ? (
              <GoogleMapReact
                bootstrapURLKeys={{key: "AIzaSyBEgdX22d4uCOdZM9-mhS8Vfd1kdW-obU8"}}
                defaultCenter={{lat: 50.7606341, lng: 14.0842337}}
                defaultZoom={12}
                options={{
                  disableDefaultUI: true,
                  styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#d2d2d2"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#565c50"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
                }}
              >
                <AnyReactComponent
                  lat={50.7606341}
                  lng={14.0842337}
                />
              </GoogleMapReact>
            ) : (
              <div className="map__holder">
                <p className="mb">Vaše IP adresa bude zaslána do aplikace Google Maps</p>
                <button
                  className="button button--blue"
                  aria-label="Zobrazit mapu"
                  onClick={handleAccept}
                >
                  Zobrazit mapu
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="relative container container--claim mt sm">
          <div className="claim claim--right">
            <div className="claim__item claim__item--flex">
              <a className="claim__link claim__link--arrow claim__link--rotate" href="https://www.google.com/maps/place/Pastor+%26+syn,+s.r.o/@50.7606341,14.0816588,17z/data=!3m1!4b1!4m6!3m5!1s0x47099945f4d69645:0x301a09443e1a639d!8m2!3d50.7606341!4d14.0842337!16s%2Fg%2F11c2j7n4vd?entry=ttu" title="Pastor & syn, s.r.o." target="_blank" rel="noreferrer">Najít na mapě</a>
            </div>
          </div>
        </div>
      </div>
      <div className="block padding">
        <div className="container">
          <div className="row margin double">
            <div className="col-md-4">
              <div className="contact">
                <div className="contact__logo mb sm">
                  <Link className="contact__link" to="/" title="Pastor & syn, s.r.o.">
                    <img className="contact__img" src={Logo} alt="Pastor & syn, s.r.o." width="1301" height="244" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="row margin">
                <div className="col-md-4 mb">
                  <div className="contact">
                    <div className="contact__title">Adresa</div>
                    <address className="bold mb-0">Modrá 44, 407 01 Jílové</address>
                  </div>
                </div>
                <div className="col-md-4 mb">
                  <div className="contact">
                    <div className="contact__title">Telefon</div>
                    <a className="contact__href bold" href="tel:+420603338915" title="+420 603 338 915">+420 603 338 915</a>
                  </div>
                </div>
                <div className="col-md-4 mb">
                  <div className="contact">
                    <div className="contact__title">Email</div>
                    <a className="contact__href bold" href="mailto:pastorl@pastorasyn.cz" title="pastorl@pastorasyn.cz">pastorl@pastorasyn.cz</a>
                  </div>
                </div>
                <div className="col-md-4 mb">
                  <div className="contact">
                    <div className="contact__title">IČO</div>
                    <p className="bold mb-0">27348652</p>
                  </div>
                </div>
                <div className="col-md-4 mb">
                  <div className="contact">
                    <div className="contact__title">DIČ</div>
                    <p className="bold mb-0">CZ27348652</p>
                  </div>
                </div>
                <div className="col-md-4 mb sm">
                  <div className="contact">
                    <div className="contact__title">Úcet CZK</div>
                    <p className="bold mb-0">43-1446920287/0100</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="contact">
                    <div className="contact__title">Úcet EUR</div>
                    <p className="bold mb-0">43-8379130227/0100</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="block padding">
        <div className="container">
          <hr className="block__line block__line--big" />
        </div>
      </div>
      <div className="block padding">
        <div className="container">
          <p className="block__title"><span className="color color--green">Kontaktní osoby</span></p>
          <div className="row margin margin-top">
            <div className="col-md-4 mb sm">
              <div className="person">
                <div className="person__img">
                  <figure className="figure">
                    <Image src="contact/lukas-pastor" alt="Ing. Lukáš Pastor" width={145} height={145} />
                  </figure>
                </div>
                <div className="person__content">
                  <p className="person__title">Ing. Lukáš Pastor</p>
                  <p className="person__info">Jednatel společnosti</p>
                  <hr className="hr" />
                  <p className="person__info">
                    <a className="person__link" href="tel:+420603338915" title="+420 603 338 915">+420 603 338 915</a><br />
                    <a className="person__link" href={`mailto:${obfuscateEmail("pastor@pastorasyn.cz")}`} title={obfuscateEmail("pastor@pastorasyn.cz")}>{obfuscateEmail("pastor@pastorasyn.cz")}</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb sm">
              <div className="person">
                <div className="person__img">
                  <figure className="figure">
                    <Image src="contact/zaneta-pastorova" alt="Žaneta Pastorová" width={145} height={145} />
                  </figure>
                </div>
                <div className="person__content">
                  <p className="person__title">Žaneta Pastorová</p>
                  <p className="person__info">Jednatelka společnosti</p>
                  <hr className="hr" />
                  <p className="person__info">
                    <a className="person__link" href="tel:+420605514333" title="+420 605 514 333">+420 605 514 333</a><br />
                    <a className="person__link" href={`mailto:${obfuscateEmail("pastorova@pastorasyn.cz")}`} title={obfuscateEmail("pastorova@pastorasyn.cz")}>{obfuscateEmail("pastorova@pastorasyn.cz")}</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="person">
                <div className="person__img">
                  <figure className="figure">
                    <Image src="contact/monika-frejvaldova" alt="Monika Frejvaldová" width={145} height={145} />
                  </figure>
                </div>
                <div className="person__content">
                  <p className="person__title">Monika Frejvaldová</p>
                  <p className="person__info">Obchodní oddělení</p>
                  <hr className="hr" />
                  <p className="person__info">
                    <a className="person__link" href="tel:+420739110076" title="+420 739 110 076">+420 739 110 076</a><br />
                    <a className="person__link" href={`mailto:${obfuscateEmail("firma@pastorasyn.cz")}`} title={obfuscateEmail("firma@pastorasyn.cz")}>{obfuscateEmail("firma@pastorasyn.cz")}</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="block padding">
        <div className="container">
          <hr className="block__line block__line--small" />
        </div>
      </div>
      <div id="poptavkovy-formular" className="block padding">
        <div className="container">
          <p className="block__title"><span className="color color--green">Poptávkový formulář</span></p>
          <form className="form margin-top" onSubmit={handleSubmit(onSubmit)}>
            <div className="row margin">
              <div className="col-md-6">
                <div className={`${getGroupClassNames("name")} mb`}>
                  <label className="form__label" htmlFor="name">Jméno</label>
                  <input id="name" className="form__control form__control--input" type="text"
                    {...register("name", {
                      required: "Vyplňte, prosím, Vaše jméno",
                      onChange: () => handleChange("name")
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="name"
                    render={({ message }) => renderErrorMessage({ message }, "name")}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className={`${getGroupClassNames("surname")} mb`}>
                  <label className="form__label" htmlFor="surname">Příjmení</label>
                  <input id="surname" className="form__control form__control--input" type="text"
                    {...register("surname", {
                      required: "Vyplňte, prosím, Vaše příjmení",
                      onChange: () => handleChange("surname")
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="surname"
                    render={({ message }) => renderErrorMessage({ message }, "surname")}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className={`${getGroupClassNames("phone")} mb`}>
                  <label className="form__label" htmlFor="phone">Telefon</label>
                  <input id="phone" className="form__control form__control--input" type="tel"
                    {...register("phone", {
                      required: "Vyplňte, prosím, Váš telefon",
                      pattern: {
                        value: /^(\+420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
                        message: "Vyplňte, prosím, platný telefon"
                      },
                      onChange: () => handleChange("phone")
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="phone"
                    render={({ message }) => renderErrorMessage({ message }, "phone")}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className={`${getGroupClassNames("email")} mb`}>
                  <label className="form__label" htmlFor="email">Email</label>
                  <input id="email" className="form__control form__control--input" type="text"
                    {...register("email", {
                      required: "Vyplňte, prosím, Váš email",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Vyplňte, prosím, platný email"
                      },
                      onChange: () => handleChange("email"),
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="email"
                    render={({ message }) => renderErrorMessage({ message }, "email")}
                  />
                </div>
              </div>
            </div>
            <div className={`${getGroupClassNames("message")} mb`}>
              <label className="form__label" htmlFor="message">Zpráva</label>
              <textarea id="message" className="form__control form__control--textarea" rows="10"
                {...register("message", {
                  required: "Vyplňte, prosím, text zprávy",
                  onChange: () => handleChange("message")
                })}
              />
              <ErrorMessage
                errors={errors}
                name="message"
                render={({ message }) => renderErrorMessage({ message }, "message")}
              />
            </div>
            <div className="form__footer">
              <div className={getGroupClassNames("gdpr")}>
                <div className="check">
                  <input id="gdpr" className="check__input" type="checkbox"
                    {...register("gdpr", {
                      required: "Je třeba udělit souhlas se zpracováním osobních údajů",
                      onChange: () => handleChange("gdpr")
                    })}
                  />
                  <label className="check__label" htmlFor="gdpr"><span className="check__span">Souhlasím se <Link className="check__link" to="/ochrana-osobnich-udaju" title="Ochrana osobních údajů">zpracováním osobních údajů</Link></span></label>
                </div>
                <ErrorMessage
                  errors={errors}
                  name="gdpr"
                  render={({ message }) => renderErrorMessage({ message }, "gdpr")}
                />
              </div>
              <div className="form__group">
                <button
                  className={classNames("button", "button--submit", {
                    "button--disabled": ((formInteracted && Object.keys(errors).length > 0) || (isSubmitted && Object.keys(errors).length > 0))
                  })}
                  type="submit"
                  aria-label="Odeslat"
                  disabled={((formInteracted && Object.keys(errors).length > 0) || (isSubmitted && Object.keys(errors).length > 0))}
                >
                  Odeslat
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Kontakt;
