import { Link } from "react-router-dom";

const Breadcrumbs = ({ breadcrumbs }) => {
  return (
    <div className="breadcrumb padding pt-0 pb-0 hidden-sm">
      <div className="container">
        <ul className="breadcrumb__navigation" itemScope="" itemType="https://schema.org/BreadcrumbList">
          <li className="breadcrumb__item" itemScope="" itemType="https://schema.org/ListItem" itemProp="itemListElement">
            <Link className="breadcrumb__link" to="/" title="Domů" itemScope="" itemType="https://schema.org/Thing" itemProp="item">
              <span className="breadcrumb__name" itemProp="name">Domů</span>
            </Link>
            <meta itemProp="position" content="1" />
          </li>
          {breadcrumbs.map((breadcrumb, index) => (
            <li className="breadcrumb__item" itemScope="" itemType="https://schema.org/ListItem" itemProp="itemListElement" key={index}>
              <Link className="breadcrumb__link breadcrumb__link--active" to={breadcrumb.link} title={breadcrumb.label} itemScope="" itemType="https://schema.org/Thing" itemProp="item">
                <span className="breadcrumb__name" itemProp="name">{breadcrumb.label}</span>
              </Link>
              <meta itemProp="position" content={index + 2} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Breadcrumbs;
