import { useEffect } from "react";
import { initSmoothScroll } from "./utils";
import Use from "./Use";
import Breadcrumbs from "./Breadcrumbs";
import { Link } from "react-router-dom";
import Image from "./Image";
import HistoryItem from "./HistoryItem";
import data from "../data.json";

const Historie = () => {
  useEffect(() => {
    initSmoothScroll();
  }, []);

  const breadcrumbs = [
    { label: "Historie", link: "/historie" }
  ];

  return (
    <>
      <Use clas="" title="Historie zpracování plastu" description="Historie zpracování plastu má u nás opravdu dlouhou tradici, v roce 1991 došlo k privatizaci podniku." />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <div className="block block--video block--half padding pt-0">
        <div className="relative container container--full">
          <div className="clip clip--green clip--absolute"></div>
          <div className="container container--alt">
            <div className="row">
              <div className="col-md-5 align-self-center">
                <div className="relative clip clip--green clip--sm color--white padding">
                  <h1 className="block__title block__title--margin mb">Jsme tu již od roku 1946 a máme bohatou firemní historii.</h1>
                  <p className="block__small mb">Pokud hledáte spolehlivého partnera pro výrobu plastových výrobků, jste na správném místě.</p>
                  <Link className="button button--blue button--top" to="/kontakt#poptavkovy-formular" title="Poptávkový formulář">Poptat nás</Link>
                </div>
              </div>
            </div>
          </div>
          <figure className="figure figure--video">
            <Image src="historie" alt="Historie Pastor & syn, s.r.o." width={1920} height={1080} />
          </figure>
        </div>
        <div className="relative container container--claim hidden-sm">
          <div className="claim">
            <div className="claim__item">
              <a className="claim__link claim__link--arrow scroll" href="#historie" title="Více informací">Dozvědět se více</a>
            </div>
          </div>
        </div>
      </div>
      <div id="historie" className="block padding">
        <div className="container">
          <div className="history">
            {data.history.map((item, idx) => (
              <HistoryItem
                key={idx}
                id={`history__item-${idx + 1}`}
                year={item.year}
                text={item.text}
                isFirst={idx === 0}
              />
            ))}
            <div className="history__dot"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Historie;
