import { useState, useEffect } from "react";
import $ from "jquery";

export const removeDiacritics = (str) => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .replace(/ /g, "-");
};

export const trim = (str) => {
  return str
    .split(" ").join("");
};

export default function useOnScreen(ref, rootMargin = "0px") {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting),
      {
        rootMargin
      }
    );

    const currentRef = ref.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
      observer.disconnect();
    };
  }, [ref, rootMargin]);

  return isIntersecting;
};

export function obfuscateEmail(email) {
  return email.replace("@", "\u0040");
};

export const initSmoothScroll = () => {
  $(function() {
    $(".scroll").on("click", function(e) {
      e.preventDefault();
      $("html, body").animate({
        scrollTop: $($(this).attr("href")).offset().top
      }, 1200);
    });
  });
};
