import { useState } from "react";
import ContentLoader from "react-content-loader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

const Image = ({ src, alt, width, height, lazy = true }) => {
    const [loaded, setLoaded] = useState(false);

    return (
        <>
            {lazy ? (
                <>
                    {loaded ? null : (
                        <ContentLoader viewBox={"0 0 "+(width)+" "+(height)+""}>
                            <rect x="0" y="0" width={width} height={height} />
                        </ContentLoader>
                    )}
                    <LazyLoadImage
                        style={loaded ? {} : { display: "none" }}
                        src={require(`../themes/frontend/img/${src}.webp`)}
                        alt={alt}
                        width={width}
                        height={height}
                        effect="opacity"
                        onLoad={() => setLoaded(true)}
                    />
                </>
            ) : (
                <LazyLoadImage
                    src={require(`../themes/frontend/img/${src}.webp`)}
                    alt={alt}
                    width={width}
                    height={height}
                    effect="opacity"
                />
            )}
        </>
    );
};

export default Image;
