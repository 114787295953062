import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../themes/frontend/img/logo/logo-alt.svg";

const Icon = ({ visible, index }) => (
  <div className={`pictogram__icon ${visible ? "pictogram__icon--visible" : ""}`}>
    <img
      className="pictogram__img"
      src={require(`../themes/frontend/img/pictogram/pictogram-${index + 1}.svg`)}
      alt={`Pictogram ${index + 1}`}
      width="100"
      height="100"
    />
  </div>
);

const Pictogram = () => {
  const [visibleIcons, setVisibleIcons] = useState(Array(15).fill(false));

  useEffect(() => {
    const intervals = [];

    const toggleIconVisibility = (index) => {
      setVisibleIcons((prevIcons) => {
        const newIcons = [...prevIcons];
        newIcons[index] = !newIcons[index];
        return newIcons;
      });
    };

    for (let i = 0; i < 15; i++) {
      const randomInterval = Math.floor(Math.random() * 3000) + 5000;
      intervals.push(setInterval(() => toggleIconVisibility(i), randomInterval));
    }

    return () => intervals.forEach(clearInterval);
  }, []);

  return (
    <div className="block block--half padding">
      <div className="relative container container--full container--before">
        <div className="clip clip--blue clip--absolute"></div>
        <div className="container container--alt">
          <div className="row">
            <div className="col-md-5 align-self-center">
              <div className="relative clip clip--blue clip--sm color--white padding">
                <p className="block__text mb">Naše produkty</p>
                <p className="block__title mb">Zákázková výroba plastů na míru</p>
                <p className="block__small mb">Na zakázku pro Vás vytvoříme cokoliv z plastů ve velkém! S naším týmem zkušených odborníků a moderními technologiemi jsme připraveni realizovat vaše projekty a potřeby.</p>
                <div className="block__buttons">
                  <Link className="button button--green button--width" to="/kontakt#poptavkovy-formular" title="Mám zakázku">Mám zakázku</Link>
                  <Link className="button button--outline button--width button--margin" to="/produkty" title="Produkty">Naše produkty</Link>
                </div>
              </div>
            </div>
            <div className="col-md-7 align-self-center">
              <div className="pictogram padding">
                {visibleIcons.map((visible, index) => (
                  <Icon key={index} visible={visible} index={index} />
                ))}
                <div className="pictogram__icon pictogram__icon--visible"><img className="pictogram__img pictogram__img--logo" src={Logo} alt="Pastor & syn, s.r.o." width="140" height="140" /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pictogram;
