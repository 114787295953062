import { useEffect } from "react";
import { Link } from "react-router-dom";
import Use from "./Use";
import VideoPlayer from "./VideoPlayer";
import Video from "../themes/frontend/video/master.m3u8";
import data from "../data.json";
import { removeDiacritics, initSmoothScroll } from "./utils";
import Svg from "./Svg";
import Pictogram from "./Pictogram";
import Eu from "../themes/frontend/img/eu.svg";
import DownloadLink from "./DownloadLink";

const repeatedData = Array(3).fill(data.brand).flat();

const Home = () => {
  useEffect(() => {
    initSmoothScroll();
  }, []);

  return (
    <>
      <Use clas="" title="Tvarování plastických hmot" description="Vstřikování termoplastů, rotační natavování, tvarování desek z termoplastu." />
      <div className="block block--video block--half padding pt-0">
        <div className="relative container container--full">
          <div className="clip clip--green clip--absolute"></div>
          <div className="container container--alt">
            <div className="row">
              <div className="col-md-5 align-self-center">
                <div className="relative clip clip--green clip--sm color--white padding">
                  <h1 className="block__title block__title--margin mb">Pokud hledáte spolehlivého partnera pro výrobu plastových výrobků, jste na správném místě.</h1>
                  <Link className="button button--blue button--top" to="/vyroba" title="Výroba">Zjistit více</Link>
                </div>
              </div>
            </div>
          </div>
          {true ? (
            <VideoPlayer src={Video} />
          ) : (
            <video className="video mb sm" autoPlay loop muted playsInline poster="https://cdn.core1.agency/video/pastorasyn.cz/video-homepage.webp">
              <source src="https://cdn.core1.agency/video/pastorasyn.cz/video-homepage.mp4" type="video/mp4"/>
            </video>
          )}
        </div>
        <div className="relative container container--claim">
          <div className="claim">
            <div className="claim__item claim__item--margin">
              <a className="claim__link claim__link--address" href="https://www.google.com/maps/place/Pastor+%26+syn,+s.r.o/@50.7606341,14.0816588,17z/data=!3m1!4b1!4m6!3m5!1s0x47099945f4d69645:0x301a09443e1a639d!8m2!3d50.7606341!4d14.0842337!16s%2Fg%2F11c2j7n4vd?entry=ttu" title="Pastor & syn, s.r.o." target="_blank" rel="noreferrer">Nacházíme se na kraji Děčína v Jílovém</a>
            </div>
            <div className="claim__item">
              <a className="claim__link claim__link--arrow scroll" href="#vyroba" title="Více informací">Dozvědět se více</a>
            </div>
          </div>
        </div>
      </div>
      <div id="vyroba" className="block padding">
        <div className="container">
          <div className="row margin double">
            <div className="col-md-6 mb sm">
              <Svg src="termoplasty" id="1" />
            </div>
            <div className="col-md-6 align-self-center">
              <div className="md-padding pr-md-0">
                <p className="block__title mb"><span className="color color--green">Vstřikujeme<br className="hidden-sm" /> termoplasty</span><br /> na moderních<br className="hidden-sm" /> vstřikolisech</p>
                <p className="block__text mb">Používáme značku Zhafir s uzavírací silou od 40 do 130 tun. V součastné době máme 15 vstřikolisů.</p>
                <Link className="button button--blue" to="/vyroba" title="Výroba">Výroba</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pictogram />
      <div className="block padding">
        <div className="container">
          <div className="row margin double">
            <div className="col-md-6 mb sm">
              <Svg src="plasticke-hmoty" id="2" />
            </div>
            <div className="col-md-6 align-self-center order-md-first">
              <div className="md-padding pl-md-0">
                <p className="block__title mb">S tradicí od roku 1946 se specializujeme na <span className="color color--green">tvarování plastických hmot</span></p>
                <p className="block__text mb">Máme k dispozici moderní a progresivní technologie.</p>
                <Link className="button button--blue" to="/historie" title="Historie">Historie</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="block block--brand padding">
        <div className="container">
          <p className="block__text bold mb">Naši významní partneři</p>
          <div className="brand">
            <div className="brand__track">
              {repeatedData.map((_im, idx) => (
                <div className="brand__logo" key={idx}>
                  <img className="brand__img" src={require(`../themes/frontend/img/brand/${removeDiacritics(_im.title)}.webp`)} alt={_im.title} width={_im.width} height={_im.height} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="block block--half padding">
        <div className="container container--full">
          <div className="eu">
            <div className="container container--alt">
              <div className="row">
                <div className="col-md-8">
                  <div className="eu__flag padding">
                    <img className="eu__img mb sm" src={Eu} alt="Pastor & syn, s.r.o." width="185" height="123" />
                    <p className="eu__text mb sm">Projekt Pastor a Syn<br />je spolufinancován Evropskou unií</p>
                  </div>
                </div>
                <div className="col-md-4 align-self-center">
                  <div className="text-md-center padding">
                    <DownloadLink fileName="snizeni-energeticke-narocnosti.pdf" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
