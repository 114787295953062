import { Link, useLocation } from "react-router-dom";
import Use from "./Use";
import Breadcrumbs from "./Breadcrumbs";
import Svg from "./Svg";

const NotFound = () => {
  const location = useLocation();

  const breadcrumbs = [
    { label: "Stránka nenalezena", link: location.pathname }
  ];

  return (
    <>
      <Use clas="body--clip" title="Stránka nenalezena" description="Vstřikování termoplastů, rotační natavování, tvarování desek z termoplastu." />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <div className="block padding">
        <div className="container">
          <div className="row margin double">
            <div className="col-md-6 mb sm">
              <Svg src="stranka-nenalezena" id="10" />
            </div>
            <div className="col-md-6 align-self-center">
              <div className="md-padding pr-md-0">
                <h1 className="block__title mb"><span className="color color--green">Stránka<br className="hidden-sm" /> nenalezena</span></h1>
                <p className="block__text mb">Stránka nebyla nalezena.<br /><br />Vraťte se, prosím, na úvodní stránku.</p>
                <Link className="button button--blue" to="/" title="Úvodní stránka">Úvodní stránka</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
