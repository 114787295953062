import Use from "./Use";
import Breadcrumbs from "./Breadcrumbs";
import Svg from "./Svg";
import { Link } from "react-router-dom";

const Produkty = () => {
  const breadcrumbs = [
    { label: "Produkty", link: "/produkty" }
  ];

  return (
    <>
      <Use clas="body--clip" title="Naše výrobky z plastických hmot" description="Při výrobě našich produktů používáme moderní a progresivní technologie pro tvarování plastických hmot." />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <div className="block padding">
        <div className="container">
          <div className="row margin double">
            <div className="col-md-6 mb sm">
              <Svg src="tvarovani-plastickych-hmot" id="7" />
            </div>
            <div className="col-md-6 align-self-center">
              <div className="md-padding pr-md-0">
                <h1 className="block__subtitle mb">Naše firma se již od roku 1946 specializuje na tvarování plastických hmot s širokým sortimentem, který pružně přizpůsobujeme podle potřeb našich zákazníků.</h1>
                <p className="block__small">Využíváme nejmodernější a progresivní technologie pro tvarování plastů, které jsme připraveni aplikovat při výrobě Vašich zakázek.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="block padding">
        <div className="container">
          <div className="row margin double">
            <div className="col-md-6 mb sm">
              <Svg src="poradenska-cinnost" id="8" />
            </div>
            <div className="col-md-6 align-self-center order-md-first">
              <div className="md-padding pl-md-0">
                <p className="block__subtitle mb">Kromě samotné výroby nabízíme i poradenskou činnost našich zkušených konstruktérů, kteří Vám poskytnou pomoc při vývoji a výrobě nástrojů.</p>
                <p className="block__small">Tuto podporu poskytujeme při všech třech technologiích, které v naší firmě využíváme – vstřikování termoplastů, rotační natavování a tvarování desek z termoplastů.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="block padding">
        <div className="container">
          <div className="row margin double">
            <div className="col-md-6 mb sm">
              <Svg src="optimalni-reseni" id="9" />
            </div>
            <div className="col-md-6 align-self-center">
              <div className="md-padding pr-md-0">
                <p className="block__subtitle mb">Jsme otevřeni projednání každého specifického požadavku a rádi se s vámi sejdeme, abychom společně našli optimální řešení.</p>
                <Link className="button button--blue" to="/kontakt#poptavkovy-formular" title="Poptávkový formulář">Poptat nás</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Produkty;
