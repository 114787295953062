import Use from "./Use";
import Breadcrumbs from "./Breadcrumbs";
import { obfuscateEmail } from "./utils";

const OchranaOsobnichUdaju = () => {
  const breadcrumbs = [
    { label: "Ochrana osobních údajů", link: "/ochrana-osobnich-udaju" }
  ];

  return (
    <>
      <Use clas="" title="Ochrana osobních údajů" description="Ochrana osobních údajů společnosti Pastor & syn, s.r.o." />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <div className="block padding">
        <h1>Ochrana osobních údajů společnosti <span className="nowrap">Pastor & syn, s.r.o.</span></h1>
        <p className="margin-top">Datum účinnosti od: 10. 5. 2016</p>
        <p>Rozsah a účel shromažďování a zpracování osobních údajů:</p>
        <p>Společnost <span className="nowrap">Pastor & syn, s.r.o.</span> se sídlem Modrá 44, 407 02 Jílové a identifikačním číslem 27348652, získává a zpracovává Vaše osobní údaje z důvodu:</p>
        <ul>
          <li>správného poskytování služeb, které nabízíme</li>
          <li>možnosti odpovědět na Vaše případné dotazy či požadavky</li>
          <li>pochopení a vyjasnění Vašich potřeb</li>
          <li>správného vedení naší interní evidence</li>
        </ul>
        <p>K tomuto účelu shromažďujeme Vaše osobní údaje v rozsahu: jméno, příjmení, e-mailovou adresu a telefonní číslo. Tyto údaje uchováváme na základě Vámi poskytnutého souhlasu. Při ochraně Vašich osobních údajů se řídíme zákonem č. 101/2000 Sb. o ochraně osobních údajů.</p>
        <h2>Poskytnutí osobních údajů klientem</h2>
        <p>Při návštěvě našich webových stránek nám můžete poskytnout své osobní údaje vyplněním on-line poptávky. Požádáme Vás o poskytnutí kontaktních údajů (jméno, příjmení, e-mailová adresa a telefonní číslo) a souhlas se zpracováním Vašich osobních údajů. Klient souhlasí s tím, aby uvedené osobní údaje byly zahrnuty do elektronické databáze společnosti <span className="nowrap">Pastor & syn, s.r.o.</span> a aby byly shromažďovány a zpracovávány v souladu se zákonem č. 101/2000 Sb. o ochraně osobních údajů, v platném znění a NAŘÍZENÍ EVROPSKÉHO PARLAMENTU A RADY (EU) 2016/679 ze dne 27. dubna 2016 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů. Pokud si v budoucnu nepřejete, abychom shromažďovali osobní údaje o Vás, můžete souhlas kdykoliv odvolat odesláním e-mailu na <a className="color color--link" href={`mailto:${obfuscateEmail("firma@pastorasyn.cz")}`} title={obfuscateEmail("firma@pastorasyn.cz")}>{obfuscateEmail("firma@pastorasyn.cz")}</a>.</p>
        <h2>Přístup k Vašim osobním údajům</h2>
        <p>Máte právo požádat nás o sdělení rozsahu osobních údajů, které o Vás shromažďujeme. V případě zájmu můžete požádat mailem o kopii Vašich osobních údajů na adrese <a className="color color--link" href={`mailto:${obfuscateEmail("firma@pastorasyn.cz")}`} title={obfuscateEmail("firma@pastorasyn.cz")}>{obfuscateEmail("firma@pastorasyn.cz")}</a>.<br />Máte právo nás emailem kdykoliv požádat o vymazání Vašich osobních údajů na mailové adrese <a className="color color--link" href={`mailto:${obfuscateEmail("firma@pastorasyn.cz")}`} title={obfuscateEmail("firma@pastorasyn.cz")}>{obfuscateEmail("firma@pastorasyn.cz")}</a>.</p>
        <h2>Informace třetím stranám</h2>
        <p>Vaše osobní údaje neposkytneme třetím stranám pro použití při marketingu jejich produktů nebo služeb bez Vašeho souhlasu.</p>
        <h2>Způsob ochrany osobních údajů</h2>
        <p>Návštěvou této webové stránky nám poskytujete svůj souhlas pro získávání, shromažďování a zpracovávání Vašich osobních údajů podle výše uvedených pravidel. Zabezpečili jsme webové stránky odpovídajícím způsobem tak, aby nedošlo k žádnému nepovolenému sdělení, využití, změně nebo zničení osobních údajů, které nám prostřednictvím stránek poskytnete. Pokud máte dotazy ohledně zabezpečení našich webových stránek, zašlete je e-mailem na adresu <a className="color color--link" href={`mailto:${obfuscateEmail("firma@pastorasyn.cz")}`} title={obfuscateEmail("firma@pastorasyn.cz")}>{obfuscateEmail("firma@pastorasyn.cz")}</a>.</p>
        <h2>Aktualizace Vašich údajů</h2>
        <p>Máte možnost své osobní údaje zkontrolovat, změnit nebo požádat o jejich vymazání zasláním e-mailu na adresu <a className="color color--link" href={`mailto:${obfuscateEmail("firma@pastorasyn.cz")}`} title={obfuscateEmail("firma@pastorasyn.cz")}>{obfuscateEmail("firma@pastorasyn.cz")}</a>.</p>
        <h2>Předávání údajů do jiných zemí</h2>
        <p>Společnost <span className="nowrap">Pastor & syn, s.r.o.</span> nepředává osobní údaje do jiných zemí.</p>
        <h2>Ochrana osobních údajů dětí</h2>
        <p>Tyto stránky jsou určeny osobám, které dosáhli 18 let věku. Naše společnost vědomě osobní údaje jakýchkoliv osob mladších 18-ti let neshromažďuje. Pokud se stane, že jsme neúmyslně obdrželi osobní údaje od návštěvníka stránek, který dosud nedosáhl věku 18 let, vymažeme tyto údaje z našich záznamů. Pokud jste ještě nedosáhli věku 18 let, můžete si naše webové stránky prohlížet, ale nesmíte nám poskytovat osobní údaje. Není možné, abyste se na stránkách zaregistrovali.</p>
        <h2>Aktualizace našeho vnitřního předpisu o ochraně osobních údajů</h2>
        <p>Podle změny právních předpisů a nabídky našich služeb, budeme tento interní předpis aktualizovat. Zde zveřejníme jeho upravené znění. Datum účinnosti tohoto předpisu naleznete na začátku této stránky.</p>
        <h2>Souhlas se zpracováním osobních údajů</h2>
        <p>Potvrzuji, že poskytnutím svých osobních údajů společnosti <span className="nowrap">Pastor & syn, s.r.o.</span> uděluji svůj souhlas se zpracováním svých osobních údajů na dobu neurčitou pro účely které vyplývají z nabídkového formuláře.</p>
        <p>Dále potvrzuji, že jsem byl/a řádně informován/a:</p>
        <ul>
          <li>rozsahu a účelu zpracovávaných osobních údajů</li>
          <li>o svém nepodmíněném právu kdykoliv tento svůj souhlas odvolat, jakož i o právu získat přístup k údajům o mé osobě a změnit je</li>
          <li>o svém právu požadovat kdykoliv informace o zpracovávání mých osobních údajů</li>
        </ul>
        <h2>Pasivně poskytované informace</h2>
        <p>Při pohybu na webové prezentaci mohou být jisté informace pasivně shromažďovány (tj. získány, aniž by byly z Vaší strany aktivně poskytnuty) za použití různých technologií (jako například cookies, javascriptové měřicí systémy, zejména Google Analytics, a standardní logování Vašich HTTP požadavků na straně serveru).</p>
        <p>Váš internetový prohlížeč (browser) automaticky předává těmto stránkám některé z uvedených anonymních informací, např. URL webového portálu či vyhledávače, který jste právě opustil/a (tzv. Referrer URL), vaši IP adresu, verzi browseru a operačního systému, který Váš počítač momentálně používá, výchozí nastavení jazyka, případně informace o nainstalovaných doplňcích Vašeho prohlížeče. Tyto stránky pro svůj provoz vyžadují ukládání cookies ve vašem prohlížeči. Můžete jej nastavit tak, aby Vás informoval v okamžiku, kdy je cookie zaslána, anebo cookies zcela odmítnout či zakázat, nicméně určité části těchto webových stránek nemusí bez cookies správně fungovat.</p>
      </div>
    </>
  );
}

export default OchranaOsobnichUdaju;
