import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Use from "./Use";
import Breadcrumbs from "./Breadcrumbs";
import Svg from "./Svg";

const FormularOdeslan = () => {
  const breadcrumbs = [
    { label: "Formulář odeslán", link: "/formular-odeslan" }
  ];

  const navigate = useNavigate();

  useEffect(() => {
    const cookieValue = Cookies.get("formSubmitted");
    if(cookieValue !== "true") {
      //navigate("/");
    }
  }, [navigate])

  return (
    <>
      <Use clas="body--clip" title="Formulář odeslán" description="Vstřikování termoplastů, rotační natavování, tvarování desek z termoplastu." />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <div className="block padding">
        <div className="container">
          <div className="row margin double">
            <div className="col-md-6 mb sm">
              <Svg src="formular-odeslan" id="11" />
            </div>
            <div className="col-md-6 align-self-center">
              <div className="md-padding pr-md-0">
                <h1 className="block__title mb"><span className="color color--green">Formulář<br className="hidden-sm" /> odeslán</span></h1>
                <p className="block__text mb">Formulář byl úspěšně odeslán, budeme Vás co nejdříve kontaktovat.<br /><br />Vraťte se, prosím, na úvodní stránku.</p>
                <Link className="button button--blue" to="/" title="Úvodní stránka">Úvodní stránka</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormularOdeslan;
