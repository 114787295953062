import { useState, useEffect } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";

const Svg = ({ src, id }) => {
  const [dots, setDots] = useState([
    { visible: false },
    { visible: false },
  ]);

  useEffect(() => {
    const intervals = [];

    const toggleVisibility = (index) => {
      setDots(prevDots =>
        prevDots.map((d, i) => i === index ? { ...d, visible: !d.visible } : d)
      );
    };

    for (let i = 0; i < dots.length; i++) {
      const randomTime = Math.floor(Math.random() * 3000) + 1000;
      const interval = setInterval(() => toggleVisibility(i), randomTime);
      intervals.push(interval);
    }

    return () => intervals.forEach(clearInterval);
  }, [dots.length]);

  return (
    <LazyLoadComponent>
      <div className="svg">
        <svg className="svg__img" width="770" height="868" viewBox="0 0 770 868" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <mask id={`mask${id}`} className="svg__mask" maskUnits="userSpaceOnUse" x="0" y="0" width="770" height="868">
            <path fillRule="evenodd" clipRule="evenodd" d="M252 0C112.824 0 0 112.824 0 252V492C0 631.176 112.824 744 252 744C267.857 744 283.373 742.535 298.418 739.734C341.658 816.305 423.792 868 518 868C657.176 868 770 755.176 770 616V376C770 236.824 657.176 124 518 124C502.143 124 486.627 125.465 471.582 128.266C428.342 51.6952 346.208 0 252 0Z"/>
          </mask>
          <g mask={`url(#mask${id})`}>
            <rect x="-139" width="1048" height="890" fill={`url(#pattern${id})`}/>
          </g>
          <defs>
            <pattern id={`pattern${id}`} patternContentUnits="objectBoundingBox" width="1" height="1">
              <use xlinkHref={`#image${id}`} transform="matrix(0.00104457 0 0 0.00123001 -0.0473555 0)"/>
            </pattern>
            <image id={`image${id}`} width="1048" height="813" xlinkHref={require(`../themes/frontend/img/${src}.webp`)} />
          </defs>
        </svg>
        <div className={`svg__dot svg__dot--small ${dots[0].visible ? "" : "svg__dot--hidden"}`}></div>
        <div className={`svg__dot svg__dot--big ${dots[1].visible ? "" : "svg__dot--hidden"}`}></div>
      </div>
    </LazyLoadComponent>
  );
};

export default Svg;
