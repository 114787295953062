import Use from "./Use";
import Pictogram from "./Pictogram";
import Breadcrumbs from "./Breadcrumbs";
import Svg from "./Svg";

const Vyroba = () => {
  const breadcrumbs = [
    { label: "Výroba", link: "/vyroba" }
  ];

  return (
    <>
      <Use clas="body--clip" title="Výroba plastických hmot" description="Vstřikujeme všechny druhy termoplastů, zaměřujeme se na zakázkovou nástrojařskou výrobu, vyrábíme nádrže o objemu až 600 litrů a zpracováváme desky z termoplastu." />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <div className="block padding">
        <div className="container">
          <h1 className="sr-only">Výroba plastických hmot</h1>
          <div className="row margin double">
            <div className="col-md-6 mb sm">
              <Svg src="vstrikovani-plastu" id="3" />
            </div>
            <div className="col-md-6 align-self-center">
              <div className="md-padding pr-md-0">
                <h2 className="block__title mb"><span className="color color--green">Vstřikování<br className="hidden-sm" /> plastů</span></h2>
                <p className="block__text mb">Vstřikujeme všechny druhy termoplastů na moderních vstřikolisech značky Zhafir s uzavírací silou od 40 do 130 tun. V součastné době máme 15 vstřikolisů.</p>
                <p className="block__text bold"><span className="color color--green">01</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="block padding">
        <div className="container">
          <div className="row margin double">
            <div className="col-md-6 mb sm">
              <Svg src="nastrojarska-vyroba" id="4" />
            </div>
            <div className="col-md-6 align-self-center order-md-first">
              <div className="md-padding pl-md-0">
                <h2 className="block__title mb"><span className="color color--green">Nástrojařská<br className="hidden-sm" /> výroba</span></h2>
                <p className="block__text mb">Zajišťujeme kompletní servis od návrhu výlisku, konstrukce formy až po vlastní fyzickou realizaci výroby vstřikovacích forem. K samozřejmosti u nás patří i servis a údržba forem zákazníků. Dále se zaměřujeme na zakázkovou nástrojařskou výrobu.</p>
                <p className="block__text bold"><span className="color color--green">02</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="block padding">
        <div className="container">
          <div className="row margin double">
            <div className="col-md-6 mb sm">
              <Svg src="rotacni-natavovani" id="5" />
            </div>
            <div className="col-md-6 align-self-center">
              <div className="md-padding pr-md-0">
                <h2 className="block__title mb"><span className="color color--green">Rotační<br className="hidden-sm" /> natavování</span></h2>
                <p className="block__text mb">V této moderní technologii jsme schopni vyrábět nádrže o objemu až 600 litrů.</p>
                <p className="block__text bold"><span className="color color--green">03</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="block padding">
        <div className="container">
          <div className="row margin double">
            <div className="col-md-6 mb sm">
              <Svg src="svarovani-a-tvarovani-desek" id="6" />
            </div>
            <div className="col-md-6 align-self-center order-md-first">
              <div className="md-padding pl-md-0">
                <h2 className="block__title mb"><span className="color color--green">Svařování<br className="hidden-sm" /> a tvarování<br className="hidden-sm" /> desek</span></h2>
                <p className="block__text mb">Věnujeme se převážně zpracováním desek z PP, PE a PVC na septiky, bazény a jiné nádrže dle potřeb zákazníka.</p>
                <p className="block__text bold"><span className="color color--green">04</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pictogram />
    </>
  );
};

export default Vyroba;
