import { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ReactLenis } from "@studio-freight/react-lenis";
import Cookies from "js-cookie";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Vyroba from "./components/Vyroba";
import Produkty from "./components/Produkty";
import Historie from "./components/Historie";
import Kontakt from "./components/Kontakt";
import FormularOdeslan from "./components/FormularOdeslan";
import OchranaOsobnichUdaju from "./components/OchranaOsobnichUdaju";
import NotFound from "./components/NotFound";

const App = () => {
  useEffect(() => {
    var myResizeFunction = function() {
      var scroller = document.documentElement;
      requestAnimationFrame(()=>{
        scroller.style
          .setProperty(
            "--scrollbar-width",
            window.innerWidth - scroller.clientWidth + "px"
          );
      });
    };

	  myResizeFunction()

    document.documentElement.className=document.documentElement.className.replace("no-js","js");

    const cookieValue = Cookies.get("formSubmitted");
    if(cookieValue === "true") {
      Cookies.set("formSubmitted", false);
    }
  }, [])

  return (
    <HelmetProvider>
      <BrowserRouter>
        <ReactLenis root>
          <Header />
          <main>
            <Routes>
              <Route path="/" exact element={<Home />} />
              <Route path="/vyroba" element={<Vyroba />} />
              <Route path="/produkty" element={<Produkty />} />
              <Route path="/historie" element={<Historie />} />
              <Route path="/kontakt" element={<Kontakt />} />
              <Route path="/formular-odeslan" element={<FormularOdeslan />} />
              <Route path="/ochrana-osobnich-udaju" element={<OchranaOsobnichUdaju />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
          <Footer />
        </ReactLenis>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
