import { useEffect, useRef } from "react";
import Hls from "hls.js";

const VideoPlayer = ({ src }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        if (Hls.isSupported()) {
            const hls = new Hls();
            hls.loadSource(src);
            hls.attachMedia(videoRef.current);
            hls.on(Hls.Events.MANIFEST_PARSED, () => {
                videoRef.current.play();
            });
        } else if (videoRef.current.canPlayType("application/vnd.apple.mpegurl")) {
            videoRef.current.src = src;
            videoRef.current.addEventListener("loadedmetadata", () => {
                videoRef.current.play();
            });
        }
    }, [src]);

    return (
        <video
            className="video mb sm"
            ref={videoRef}
            autoPlay
            muted
            loop
            playsInline
        />
    );
};

export default VideoPlayer;
