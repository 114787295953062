import { useState, useEffect } from "react";
import { initSmoothScroll } from "./utils";
import { useLocation, Link } from "react-router-dom";
import Logo from "../themes/frontend/img/logo/logo-white.svg";

const Header = () => {
  useEffect(() => {
    initSmoothScroll();
  }, []);

  const [open, setOpen] = useState(false);
  const menuToggle = () => {
    open ? setOpen(false) : setOpen(true);
    open ? document.body.classList.remove("body--overflow") : document.body.classList.add("body--overflow");
	};
  const location = useLocation();

  return (
    <>
      <header className="header padding" itemScope="" itemType="http://schema.org/WPHeader">
        <nav className={`nav ${ open ? "nav--after" : "" }`} itemScope="" itemType="http://schema.org/SiteNavigationElement">
          <div className="logo">
            <Link className="logo__link" to="/" title="Pastor & syn, s.r.o." onClick={() => setOpen(false)}>
              <img className="logo__img" src={Logo} alt="Pastor & syn, s.r.o." width="1301" height="244" />
            </Link>
          </div>
          <span className="dash md-hidden" onClick={() => menuToggle()}>
            <span className={`dash__line dash__line--margin ${ open ? "dash__line--plus" : "" }`}></span>
            <span className={`dash__line dash__line--margin  dash__line--short ${ open ? "dash__line--zero" : "" }`}></span>
            <span className={`dash__line ${ open ? "dash__line--minus" : "" }`}></span>
          </span>
          <div className={`menu ${ open ? "menu--open" : "" }`}>
            <ul className="menu__list">
              <li className="menu__item"><Link className={`menu__link ${location.pathname === "/vyroba" ? "menu__link--active" : ""}`} to="/vyroba" title="Výroba" onClick={() => setOpen(false)}>Výroba</Link></li>
              <li className="menu__item"><Link className={`menu__link ${location.pathname === "/produkty" ? "menu__link--active" : ""}`} to="/produkty" title="Produkty" onClick={() => setOpen(false)}>Produkty</Link></li>
              <li className="menu__item"><Link className={`menu__link ${location.pathname === "/historie" ? "menu__link--active" : ""}`} to="/historie" title="Historie" onClick={() => setOpen(false)}>Historie</Link></li>
              <li className="menu__item"><Link className={`menu__link ${location.pathname === "/kontakt" ? "menu__link--active" : ""}`} to="/kontakt" title="Kontakt" onClick={() => setOpen(false)}>Kontakt</Link></li>
            </ul>
            <hr className="hr md-hidden" />
            <div className="margin-top md-hidden">
              {location.pathname === "/kontakt" ?
                <a className="button button--green button--inquiry scroll mb" href="#poptavkovy-formular" title="Poptávka" onClick={() => setOpen(false)}>Poptávka</a>
              :
                <Link className="button button--green button--inquiry mb" to="/kontakt#poptavkovy-formular" title="Poptávka" onClick={() => setOpen(false)}>Poptávka</Link>
              }
              <div className="menu__bottom">
                <div className="row no-col justify-content-space-between">
                  <div className="menu__contact">
                    <div className="menu__title">Telefon</div>
                    <a className="menu__href" href="tel:+420739110076" title="+420 739 110 076">+420 739 110 076</a>
                  </div>
                  <div className="menu__contact">
                    <div className="menu__title">Email</div>
                    <a className="menu__href" href="mailto:firma@pastorasyn.cz" title="firma@pastorasyn.cz">firma@pastorasyn.cz</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {location.pathname === "/kontakt" ?
            <a className="button button--green button--inquiry scroll hidden-sm" href="#poptavkovy-formular" title="Poptávka">Poptávka</a>
          :
            <Link className="button button--green button--inquiry hidden-sm" to="/kontakt#poptavkovy-formular" title="Poptávka">Poptávka</Link>
          }
        </nav>
      </header>
    </>
  );
};

export default Header;
